export default {

  updateWallet (state, updatedState) {
    state.connected = updatedState.connected;
    state.pkh = updatedState.pkh;
    state.pkhDomain = updatedState.pkhDomain;
    clearInterval(state.updateBalanceInt);
    state.updateBalanceInt = updatedState.updateBalanceInt;
  },

  updateWalletBalance (state, balance) {
    state.balance = balance;
  },

  updateWalletTokens (state, tokens){
    state.tokens = tokens.map(({token}) => {
        return {
            thumbnailUri: token.metadata.thumbnailUri.replace('ipfs://','https://ipfs.io/ipfs/'),
            tokenId: token.tokenId,
            name: token.metadata.name
        }
    });
  }
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Home from './components/Home';
import BattlesPage from './components/BattlesPage';
import BattleCreate from './components/BattleCreate';
import BattleInspect from './components/BattleInspect';
import './plugins/element.js';
import store from './store';
import VueNumberFormat from 'vue-number-format';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(VueRouter);
Vue.use(vueAwesomeCountdown, 'countdown');
Vue.use(VueNumberFormat, {
    prefix: '',
    decimal: '.',
    thousand: ',',
    precision: 4,
});
Vue.use(Toast);
Vue.config.productionTip = false;

const routes = [
    { path: '/', component: Home },
    { path: '/battle', component: BattlesPage, meta: { title: '' } },
    { path: '/battle/create', component: BattleCreate, meta: { title: 'New Fight!' } },
    { path: '/battle/:hash', component: BattleInspect, meta: { title: 'Check this Battle' }, props: true },
];

const router = new VueRouter({
    routes,
});

const DEFAULT_TITLE = 'Chop Sumo - A 3,000 Unique NTF Series';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

import { BigNumber } from "bignumber.js";
import { hex2buf, mergebuf } from "@taquito/utils";
import { OpKind } from '@taquito/taquito';
import blake from 'blakejs'
import {
  requestPermissions,
  getActiveAccount,
  getUserNfts,
  clearActiveAccount,
  getWalletContract,
  wallet,
  Tezos,
} from "../../utils/tezos";
import tzkt from "../../utils/tzkt";
import tzdomains from "./../../utils/tezos-domains";

const moveParams = {
    butt: '00',
    slam: '01',
    kick: '02',
    throw: '03',
    punch: '04',
}

const paramMoves = {
    '00': 'butt',
    '01': 'slam',
    '02': 'kick',
    '03': 'throw',
    '04': 'punch',
}

const BATTLE = process.env.VUE_APP_BATTLE_CONTRACT

export default {
  async connectWallet({ commit, state, dispatch }) {
    if (!state.connected) {
      return getActiveAccount().then((account) => {
        if (account) {
          commit("updateWallet", {
            connected: true,
            pkh: account.address,
            pkhDomain: tzdomains.resolveAddressToName(
              account.address,
              `${account.address.substr(0, 6)}...${account.address.substr(-6)}`
            ),
            updateBalanceInt: null,
            tokens: []
          });
          dispatch("queryForNfts");
          dispatch("walletConnected");
        }
      });
    }
  },

  async disconnectWallet({ commit, state, dispatch }) {
    clearActiveAccount().then(() => {
      clearInterval(state.updateBalanceInt);
      commit("updateWallet", {
        connected: false,
        pkh: "",
        pkhDomain: Promise.resolve(""),
        updateBalanceInt: null,
        tokens: []
      });
      dispatch("queryForNfts");
    });
  },

  async updateWalletBalanceZZZ({ commit, state }) {
    if (state.connected) {
      commit("updateWalletBalance", await Tezos.tz.getBalance(state.pkh));
    } else {
      commit("updateWalletBalance", new BigNumber(0));
    }
  },

  async init({ commit, dispatch }) {
    wallet.client.getActiveAccount().then((account) => {
      if (account) {
        commit("updateWallet", {
          connected: true,
          pkh: account.address,
          pkhDomain: tzdomains.resolveAddressToName(
            account.address,
            `${account.address.substr(0, 6)}...${account.address.substr(-6)}`
          ),
          updateBalanceInt: null
        });
        dispatch("queryForNfts");
        dispatch('walletConnected');
      }
    });
  },

  async createGame ({ state, dispatch }, { id, game, fee, secret }) {
    try {
      await dispatch('connectWallet')

      const [mov1, mov2, mov3] = [moveParams[game[0]], moveParams[game[1]], moveParams[game[2]]]

      const payload = hex2buf(`${mov1}${mov2}${mov3}`)

      const commitment = blake.blake2bHex(mergebuf(payload, hex2buf(secret)), null, 32)

      const fa2 = await getWalletContract(process.env.VUE_APP_FA2_CONTRACT)
      const contract = await getWalletContract(BATTLE)
      const batch = await Tezos.wallet.batch([{
            kind: OpKind.TRANSACTION,
            ...fa2.methods.update_operators([{ add_operator: { owner: state.pkh, operator: BATTLE, token_id: id } }]).toTransferParams()
        },
        {
            kind: OpKind.TRANSACTION,
            ...contract.methods.create(id, commitment).toTransferParams(),
            amount: fee,
            mutez: true
        }
      ])
      const op = await batch.send()
      const result = await op.confirmation(1)
      if (result.completed) {
        return { opHash: op.opHash, commitment, game, secret }
      }
      throw new Error('Create game transaction failed')
    } catch (e) {
      if (e.title === 'Aborted') return false
      throw e
    }
  },

  async revealMoves ({ dispatch }, { gameId, reveal }) {
    try {
        await dispatch('connectWallet')

        const [mov1, mov2, mov3, secret] = [moveParams[reveal[0]], moveParams[reveal[1]], moveParams[reveal[2]], reveal[3]]

        const contract = await getWalletContract(BATTLE)

        const op = await contract.methods.reveal(gameId, mov1, mov2, mov3, secret).send()
        const result = await op.confirmation(1)
        if (result.completed) {
            return true
        }
        throw new Error('Reveal moves transaction failed')
    } catch (e) {
        if (e.title === 'Aborted') return false
        throw e
    }
  },

  async settleUnrevealed ({ dispatch }, { gameId }) {
    try {
        await dispatch('connectWallet')

        const contract = await getWalletContract(BATTLE)

        const op = await contract.methods.settle_unrevealed(gameId).send()
        const result = await op.confirmation(1)
        if (result.completed) {
            return true
        }
        throw new Error('Settle unrevealed transaction failed')
    } catch (e) {
        if (e.title === 'Aborted') return false
        throw e
    }
  },

  async cancelGame ({ dispatch }, { gameId }) {
    try {
        await dispatch('connectWallet')

        const contract = await getWalletContract(BATTLE)

        const op = await contract.methods.cancel(gameId).send()
        const result = await op.confirmation(1)
        if (result.completed) {
            return true
        }
        throw new Error('Cancel game transaction failed')
    } catch (e) {
        if (e.title === 'Aborted') return false
        throw e
    }
  },

  async joinGameAndReveal ({ state, dispatch }, { id, gameId, game, fee, secret }) {
    try {
      await dispatch('connectWallet')

      const [mov1, mov2, mov3] = [moveParams[game[0]], moveParams[game[1]], moveParams[game[2]]]

      const payload = hex2buf(`${mov1}${mov2}${mov3}`)

      const commitment = blake.blake2bHex(mergebuf(payload, hex2buf(secret)), null, 32)
      console.log(mov1, mov2, mov3, secret)
      const fa2 = await getWalletContract(process.env.VUE_APP_FA2_CONTRACT)
      const contract = await getWalletContract(BATTLE)
      const batch = await Tezos.wallet.batch([{
            kind: OpKind.TRANSACTION,
            ...fa2.methods.update_operators([{ add_operator: { owner: state.pkh, operator: BATTLE, token_id: id } }]).toTransferParams()
        },
        {
            kind: OpKind.TRANSACTION,
            ...contract.methods.join(gameId, id, commitment).toTransferParams(),
            amount: fee,
            mutez: true
        },
        {
            kind: OpKind.TRANSACTION,
            ...contract.methods.reveal(gameId, mov1, mov2, mov3, secret).toTransferParams()
        }
      ])
      const op = await batch.send()
      const result = await op.confirmation(1)
      if (result.completed) {
        return { opHash: op.opHash, commitment, game, secret }
      }
      throw new Error('Join game transaction failed')
    } catch (e) {
      if (e.title === 'Aborted') return false
      throw e
    }
  },

  async getGameHash (_, transactionHash) {
    const { data: transactions } = await tzkt.getTransaction(transactionHash);
    const transaction = transactions?.find(t => t.target.address === BATTLE)
    if (!transaction) return
    const diff = transaction?.diffs.find(({path}) => path === 'games')
    if (diff?.content) return `${diff.content.hash}-${diff.content.key}`
  },

  async changeWallet({ dispatch }) {
    requestPermissions().then(() => {
      dispatch("init");
    });
  },

  walletConnected() {
    console.log('walletConnected')
  },

  async queryForNfts({ commit }) {
    const account = await wallet.client.getActiveAccount()
    let tokens = []
    if (account?.address) {
      tokens = await getUserNfts(account.address)
    }
    commit("updateWalletTokens", tokens);
  },

  async queryForGames(_, params = {} ) {
    const { data } = await tzkt.getContractBigMapKeys(BATTLE, 'games', { active: true, limit: 100, 'sort.desc': 'id', select: 'key,value,hash', ...params })
    return data
  },

  async loadGame (_, id) {
    const { data } = await tzkt.getContractBigMapKeys(BATTLE, 'games', { active: true, limit: 1, select: 'key,value,hash', key: id })
    const game = data[0]?.value
    if (game) {
        if (game.player1?.reveal) {
            const { bytes_0, bytes_1, bytes_2, bytes_3 } = game.player1.reveal
            game.player1.reveal = [paramMoves[bytes_0], paramMoves[bytes_1], paramMoves[bytes_2], bytes_3];
        }
        if (game.player2?.reveal) {
            const { bytes_0, bytes_1, bytes_2, bytes_3 } = game.player2.reveal
            console.log(bytes_0, bytes_1, bytes_2, bytes_3)
            game.player2.reveal = [paramMoves[bytes_0], paramMoves[bytes_1], paramMoves[bytes_2], bytes_3];
        }
    }
    return game
  },

  async loadSumos(_, ids) {
    if (ids.length === 0) return []
    const params = { contract: process.env.VUE_APP_FA2_CONTRACT }
    if (ids.length === 1) {
        params.tokenId = ids[0]
    } else {
        params['tokenId.in'] = ids.join(',')
    }
    const { data } = await tzkt.getTokens(params)
    return data.map(({tokenId, metadata}) => ({
        name: metadata.name,
        thumbnailUri: metadata.thumbnailUri.replace('ipfs://','https://ipfs.io/ipfs/'),
        tokenId
    }))
  }
};

<template>
    <div :class="this.state === 'winner' ? 'move-display move-display-winner' : 'move-display'">
        <video v-if="this.state == 'winner'" class="video-winner" autoplay loop muted>
            <source src="mp4/winner.mp4" type="video/mp4" />
        </video>
         <video v-if="this.state == 'winner'" class="video-winner" autoplay loop muted>
            <source src="mp4/winner.mp4" type="video/mp4" />
        </video>
        <div class="move" v-if="name === 'slam'">
            <h3>Body Slam</h3>
            <video class="video" autoplay loop muted>
                <source src="mp4/body_blaster.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="move" v-if="name === 'butt'">
            <h3>Headbutt</h3>
            <video class="video" autoplay loop muted>
                <source src="mp4/headbutt.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="move" v-if="name === 'punch'">
            <h3>Power Punch</h3>
            <video class="video" autoplay loop muted>
                <source src="mp4/flying_punch.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="move" v-if="name === 'kick'">
            <h3>Flying Kick</h3>
            <video class="video" autoplay loop muted>
                <source src="mp4/flying_kick.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="move" v-if="name === 'throw'">
            <h3>Overhead Throw</h3>
            <video class="video" autoplay loop muted>
                <source src="mp4/overhead_throw.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="move" v-if="name === 'hidden'">
            <div class="video">
              <img src="./../assets/hidden.svg" />
            </div>
        </div>
        <div class="move" v-if="name === 'unknown'">
            <h3>???</h3>
            <div class="video"></div>
        </div>
    </div>
</template>

<script>
export default {
    // ['slam', 'butt', 'punch', 'kick', 'throw'],
    name: 'MoveDisplay',
    props: ['name','state'],
};
</script>

<template>
    <div id="battle">
        <div class="battle-container">
            <div class="battle-zone">
                <div id="header">
                    <div class="toolbar">
                        <div>
                            <h1>
                                <router-link class="back-link" to="/battle">&larr; Back to Battle list</router-link>
                            </h1>
                        </div>
                        <div>
                            <span v-if="!wallet.connected" @click="connectWallet">Connect Wallet</span>
                            <span plain v-else @click="disconnectWallet">Unsync</span>
                        </div>
                    </div>
                    <router-link to="/">
                        <img src="./../assets/logo.png" class="battle-logo" />
                    </router-link>
                    <h2>BATTLE!</h2>
                </div>
                <div id="timer">
                    <div>
                        <span>
                            <label id="vs">VS</label>
                        </span>
                    </div>
                </div>
                <div id="player">
                    <div class="avatar" :class="{ disabled: !wallet.connected }">
                        <div v-if="p1token" class="ipfs-image" :style="`background-image: url(${p1token.thumbnailUri})`">
                            <span class="sumo-title">{{ p1token.name }}</span>
                        </div>
                        <ThreeAvatar v-else title="Loading"></ThreeAvatar>
                    </div>
                    <div class="stats">
                        <div class="cta">
                            <el-button plain class="button" v-if="!wallet.connected"
                                @click="connectWallet">Connect Wallet</el-button>
                            <div v-if="canJoin" class="game-intro">
                                Select your three secret moves below and commit by
                                paying the 1.5 TEZ ante required to start the battle.
                            </div>

                        </div>
                    </div>
                    <div class="avatar" :class="{ disabled: !playerHasJoined }">
                        <div v-if="p2token" class="ipfs-image" :style="`background-image: url('${p2token.thumbnailUri}')`">
                            <span class="sumo-title">{{ p2token.name }}</span>
                        </div>
                        <ThreeAvatar v-else anon="true" title="Waiting Challenger"></ThreeAvatar>
                    </div>
                </div>
            </div>

            <!-- Moves Select - Game Init -->
            <div v-if="canJoin" class="panel moves-container">
                <div>
                    <div class="arena-title">Select your secret moves</div>
                    <div class="arena-instruction" v-if="$route.params.hash">Select your moves for game {{ hash }}.</div>
                </div>
                <table>
                    <tr>
                        <td>
                            <img class="table-logo" src="./../assets/logo2.png" />
                        </td>
                        <td>
                            <MoveDisplay name="slam"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="butt"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="punch"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="kick"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="throw"></MoveDisplay>
                        </td>
                    </tr>
                    <tr>
                        <td class="round round1">
                            <h4>Round 1</h4>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[0]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[1]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[2]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[3]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[4]" @update="updateMoves"></MoveSelect>
                        </td>
                    </tr>
                    <tr>
                        <td class="round round2">
                            <h4>Round 2</h4>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[0]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[1]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[2]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[3]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[4]" @update="updateMoves"></MoveSelect>
                        </td>
                    </tr>
                    <tr>
                        <td class="round round3">
                            <h4>Round 3</h4>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[0]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[1]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[2]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[3]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[4]" @update="updateMoves"></MoveSelect>
                        </td>
                    </tr>
                </table>
                <div class="action-area">
                    <div v-if="round1ChallengerMove === 'unknown' || round2ChallengerMove === 'unknown' || round3ChallengerMove === 'unknown'">
                        Select your 3 moves from above.</div>
                        <el-button plain class="button" v-if="!wallet.connected"  @click="connectWallet">Connect Wallet to Join Game</el-button>
                    <div v-if="round1ChallengerMove !== 'unknown' && round2ChallengerMove !== 'unknown' && round3ChallengerMove !== 'unknown'">
                        Nice. Now start the fight!</div>
                        <el-button plain class="button" v-if="wallet.connected" @click="joinGame" :disabled="round1ChallengerMove === 'unknown' || round2ChallengerMove === 'unknown' || round3ChallengerMove === 'unknown'">
                           <span v-if="!isWaitingToJoinGame && !playerHasJoined">Accept the challenge for &#42793;1.5</span>
                           <span v-if="isWaitingToJoinGame">Please Wait...</span>
                           <span v-if="playerHasJoined">Game Created</span>
                        </el-button>
                </div>
            </div>

            <div v-else class="moves-container" :class="isActive ? '' : 'disabled'">
                <div>
                    <div v-if="isGameOver" class="arena-title">Game Over! {{ gameOverStatus() }}</div>
                    <div v-else-if="isCancelled" class="arena-title">Game Cancelled!</div>
                    <div v-else-if="!playerHasJoined" class="arena-title">Waiting for Challenger</div>
                    <div v-else-if="isWaitingForReveal" class="arena-title">Waiting for Reveal</div>
                </div>
                <table>
                    <tr>
                        <td>
                            <div class="column-title">
                                <span>{{ player1 === wallet.pkh ? 'You' : 'Player 1' }}</span>
                                <span>{{ p1token ? p1token.name : '???' }}</span>
                            </div>
                        </td>
                        <td>
                            <img class="table-logo" src="./../assets/logo2.png" />
                        </td>
                        <td>
                            <div class="column-title">
                                <span>{{ player2 === wallet.pkh ? 'You' : 'Challenger' }}</span>
                                <span>{{ p2token ? p2token.name : '???' }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MoveDisplay :name="round1move"
                                :state="isGameOver && doesItWin(round1move, round1ChallengerMove) === 1 && 'winner'">
                            </MoveDisplay>
                        </td>
                        <td class="round round3">
                            <h4>Round 1
                                <span>{{ isGameOver ? doesItWinCopy(round1move, round1ChallengerMove) : 'Waiting for Reveal' }}</span>
                            </h4>
                        </td>
                        <td>
                            <MoveDisplay :name="playerHasJoined ? round1ChallengerMove : 'unknown'"
                                :state="isGameOver && doesItWin(round1ChallengerMove, round1move) === 1 && 'winner'">
                            </MoveDisplay>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MoveDisplay :name="round2move"
                                :state="isGameOver && doesItWin(round2move, round2ChallengerMove) === 1 && 'winner'">
                            </MoveDisplay>
                        </td>
                        <td class="round round3">
                            <h4>Round 2
                                <span>{{ isGameOver ? doesItWinCopy(round2move,  round2ChallengerMove) : 'Waiting for Reveal' }}</span>
                            </h4>
                        </td>
                        <td>
                            <MoveDisplay :name="playerHasJoined ? round2ChallengerMove : 'unknown'"
                                :state="isGameOver && doesItWin(round2ChallengerMove, round2move) === 1 && 'winner'">
                            </MoveDisplay>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MoveDisplay :name="round3move"
                                :state="isGameOver && doesItWin(round3move, round3ChallengerMove) === 1 && 'winner'">
                            </MoveDisplay>
                        </td>
                        <td class="round round3">
                            <h4>Round 3
                                <span>{{ isGameOver ? doesItWinCopy(round3move, round3ChallengerMove) : 'Waiting for Reveal' }}</span>
                            </h4>
                        </td>
                        <td>
                            <MoveDisplay :name="playerHasJoined ? round3ChallengerMove : 'unknown'"
                                :state="isGameOver && doesItWin(round3ChallengerMove, round3move) === 1 && 'winner'">
                            </MoveDisplay>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="move">
                                <h5 class="large-text">{{ isGameOver ? player1Score() + " TEZ" : "0.0 TEZ" }}
                                </h5>
                            </div>

                        </td>
                        <td class="round round3">
                            <h4>Final Takings</h4>
                        </td>
                        <td>
                            <div class="move">
                                <h5 class="large-text">{{ isGameOver ? challengerScore() + " TEZ" : "0.0 TEZ"
                                }}
                                </h5>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="action-area">
                    <div v-if="isParticipant && isGameOver">The game has now conluded.<br /> Your wallet will be credited with your takings.</div>

                    <el-button plain class="button button-side-by-side" v-if="wallet.connected && canP1Reveal" @click="revealGame" :disabled="isWaitingToRevealMoves">
                        <span>{{ isWaitingToRevealMoves ? 'Please Wait...' : 'Reveal your moves' }}</span>
                    </el-button>
                    <el-button plain class="button button-side-by-side" v-if="wallet.connected && isParticipant && isGameOver" @click="newGame">
                        <span>Restart Game</span>
                    </el-button>
                    <el-button plain class="button button-side-by-side" v-if="isWaitingForCancel && isPlayer1" @click="cancelGame" :disabled="!canCancelGame">
                        <span v-if="canSettleUnrevealed">Cancel Game</span>
                        <span>{{ convertToSeconds(cancelTimeout) }}</span>
                    </el-button>
                    <el-button plain class="button button-side-by-side" v-if="isWaitingForReveal && isPlayer2" @click="settleUnrevealed" :disabled="!canSettleUnrevealed">
                        <span v-if="canSettleUnrevealed">Settle Unrevealed</span>
                        <span v-else>{{ convertToSeconds(revealTimeout) }}</span>
                    </el-button>
                </div>
            </div>

            <game-rules />
            <battle-footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { buf2hex } from '@taquito/utils';
import ThreeAvatar from '../components/ThreeAvatar';
import MoveSelect from '../components/MoveSelect';
import MoveDisplay from '../components/MoveDisplay';
import GameRules from '../components/Rules';
import BattleFooter from '../components/BattleFooter';

const JOIN_WINDOW = 7200
const REVEAL_WINDOW = 3600
const FEE = 1500000

export default {
    name: 'battle',
    props: {
        hash: {
            type: String,
            default: ''
        }
    },
    components: {
        ThreeAvatar,
        MoveSelect,
        MoveDisplay,
        GameRules,
        BattleFooter,
    },
    data() {
        return {
            state: 'init', // init, created, over
            isActive: true,
            movesNames: ['slam', 'butt', 'punch', 'kick', 'throw'],
            // discordUrl: 'https://discord.com/invite/mCxF7PxRh9',
            playerHasJoined: false,
            isWaitingToJoinGame: false,
            round1move: 'unknown',
            round2move: 'unknown',
            round3move: 'unknown',
            round1ChallengerMove: 'unknown',
            round2ChallengerMove: 'unknown',
            round3ChallengerMove: 'unknown',

            canCancelGame: false,

            canSettleUnrevealed: false,

            cancelTimeout: JOIN_WINDOW,
            revealTimeout:  REVEAL_WINDOW,
            isWaitingForPlayerJoin: false,
            isWaitingToRevealMoves: false,

            player1: null,
            player2: null,
            p1token: null,
            p2token: null,
            timer: null
        };
    },
    watch: {
        hash: {
            handler (value) {
                console.log({value})
                this.loadGame()
            },
            immediate: true
        }
    },
    computed: {
        ...mapState(['wallet']),
        gameId () {
            const [, id] = this.hash.split('-')
            return id || ''
        },

        isPlayer1 () {
            return this.wallet.connected && this.player1 === this.wallet.pkh
        },

        isPlayer2 () {
            return this.wallet.connected && this.player2 === this.wallet.pkh
        },

        canJoin () {
            return this.isActive && !this.player2 && !this.isPlayer1
        },

        isCancelled () {
            return !this.isActive && !this.player2
        },

        isGameOver () {
            return !this.isActive && this.player2
        },

        isWaitingForReveal () {
            return this.isActive && this.player2
        },

        canP1Reveal () {
            return this.isWaitingForReveal && this.isPlayer1
        },

        isParticipant () {
            return this.isPlayer1 || this.isPlayer2
        },

        isWaitingForCancel () {
            return this.isPlayer1 && this.isActive && !this.player2
        }
    },

    created() {
        this.init();
    },

    beforeDestroy() {
        clearTimeout(this.timer)
    },

    methods: {
        ...mapActions(['connectWallet', 'disconnectWallet', 'changeWallet', 'queryForNfts']),

        init() {
            try {
                this.queryForNfts();
            } catch (e) {
                console.log(e);
            }
            setInterval(() => {
                if (!this.playerHasJoined && this.cancelTimeout > 0) this.cancelTimeout--;
                this.canCancelGame = this.cancelTimeout <= 0
                if (this.playerHasJoined && this.revealTimeout > 0) this.revealTimeout--;
                this.canSettleUnrevealed = this.revealTimeout <= 0
            }, 1000);
        },

        async loadGame () {
            try {
                const id = this.gameId
                if (!id) throw new Error("Invalid game hash")
                const value = await this.$store.dispatch('loadGame', id)
                console.log(value)
                if (!value) {
                    throw new Error(`Can't load game #${id}`)
                }
                const { active, created, joined, player1, player2 } = value
                this.playerHasJoined = !!player2
                this.isActive = active
                this.isWaitingToPlayerJoin = !joined

                const now = Date.now()

                this.cancelTimeout = Math.round((new Date(created).getTime() - now) / 1000) + JOIN_WINDOW

                if (joined) this.revealTimeout = Math.round((new Date(joined).getTime() - now) / 1000) + REVEAL_WINDOW

                this.player1 = player1.address
                this.player2 = player2?.address
                const [p1m1, p1m2, p1m3, p1secret] = (player1.reveal || this.restoreGame(player1.token_id))

                this.round1move = p1m1 || 'unknown'
                this.round2move = p1m2 || 'unknown'
                this.round3move = p1m3 || 'unknown'

                const [p2m1, p2m2, p2m3] = (player2?.reveal || [])

                this.round1ChallengerMove = p2m1 || 'unknown'
                this.round2ChallengerMove = p2m2 || 'unknown'
                this.round3ChallengerMove = p2m3 || 'unknown'

                console.log(p1m1, p1m2, p1m3, p2m1, p2m2, p2m3, p1secret)
                if (player2) {
                    const [t1, t2] = await this.loadTokens([player1.token_id, player2.token_id])
                    if (+t1.tokenId === +player1.token_id) {
                        this.p1token = t1
                        this.p2token = t2
                    } else {
                        this.p2token = t1
                        this.p1token = t2
                    }
                } else {
                    const [t1] = await this.loadTokens([player1.token_id])
                    this.p1token = t1
                    this.p2token = null
                }
            } catch (e) {
                this.$toast.error(e.message)
                console.log(e)
            } finally {
                if (this.isActive) this.timer = setTimeout(() => this.loadGame(), 14000)
            }
        },

        async loadTokens (ids) {
            const tokens = await this.$store.dispatch('loadSumos', ids)
            return tokens
        },

        restoreGame (tokenId) {
            let result = []
            if (!tokenId) return result
            let storedGame = localStorage.getItem(tokenId)
            if (!storedGame) return result
            const { pkh, secret, game } = JSON.parse(storedGame)
            if (this.wallet.pkh === pkh) {
                result = [...game, secret]
            }
            return result
        },

        newGame () {
            this.$router.push('/battle/create')
        },

        randomHex () {
          const secret = new Uint8Array(16)
          self.crypto.getRandomValues(secret)
          return buf2hex(secret)
        },

        joinGame() {
            try {
                if (this.isWaitingToJoinGame) return
                this.isWaitingToJoinGame = true;

                const id = this.wallet.tokens[0]?.tokenId

                if (!id) throw new Error("Chop Sumo token not found");

                const { round1ChallengerMove, round2ChallengerMove, round3ChallengerMove } = this

                this.$store.dispatch('joinGameAndReveal', { gameId: this.gameId, id, fee: FEE, game: [round1ChallengerMove, round2ChallengerMove, round3ChallengerMove], secret: this.randomHex() })
            } catch (e) {
                this.$toast.error(e.message)
            } finally {
                this.isWaitingToJoinGame = false;
            }
        },

        updateMoves(round, move) {
            console.log('updates', round, move);
            if (round === 'round1') {
                this.round1ChallengerMove = move;
            }
            if (round === 'round2') {
                this.round2ChallengerMove = move;
            }
            if (round === 'round3') {
                this.round3ChallengerMove = move;
            }
        },

        async cancelGame() {
            try {
                if (this.isWaitingToRevealMoves || !this.canCancelGame) return
                this.isWaitingToRevealMoves = true;
                await this.$store.dispatch('cancelGame', { gameId: this.gameId })
            } catch (e) {
                this.$toast.error(e.message)
            } finally {
                this.isWaitingToRevealMoves = false;
            }
        },

        async revealGame() {
            try {
                if (this.isWaitingToRevealMoves) return
                this.isWaitingToRevealMoves = true;

                const reveal = this.restoreGame(this.p1token?.tokenId)

                console.log(reveal)

                if (reveal.length !== 4) throw new Error("Reveal data not found");

                await this.$store.dispatch('revealMoves', { gameId: this.gameId, reveal })
            } catch (e) {
                this.$toast.error(e.message)
            } finally {
                this.isWaitingToRevealMoves = false;
            }
        },

        async settleUnrevealed () {
            try {
                if (this.isWaitingToRevealMoves || !this.canSettleUnrevealed) return
                this.isWaitingToRevealMoves = true;
                await this.$store.dispatch('settleUnrevealed', { gameId: this.gameId })
            } catch (e) {
                this.$toast.error(e.message)
            } finally {
                this.isWaitingToRevealMoves = false;
            }
        },

        doesItWin(a, b) {
            // butt beats punch and slam.
            // slam beats trow and punch.
            // kick beats slam and butt.
            // throw beats butt and kick.
            // punch beats kick and throw.
            if (a === b) return 0.5; // Draw

            // Wins for sure
            if (a === 'butt' && ['punch', 'slam'].includes(b)) return 1;
            if (a === 'slam' && ['throw', 'punch'].includes(b)) return 1;
            if (a === 'kick' && ['slam', 'butt'].includes(b)) return 1;
            if (a === 'throw' && ['butt', 'kick'].includes(b)) return 1;
            if (a === 'punch' && ['kick', 'throw'].includes(b)) return 1;
            if (b === 'unknown') return 1;
            // Loose scenarios
            if (a === 'unknown') return 0;
            if (b === 'butt' && ['punch', 'slam'].includes(a)) return 0;
            if (b === 'slam' && ['throw', 'punch'].includes(a)) return 0;
            if (b === 'kick' && ['slam', 'butt'].includes(a)) return 0;
            if (b === 'throw' && ['butt', 'kick'].includes(a)) return 0;
            if (b === 'punch' && ['kick', 'throw'].includes(a)) return 0;

            return 0.5; // Draw (Impossible?)
        },

        doesItWinCopy(a, b) {
            if (this.doesItWin(a, b) === 1) {
                return `${a} beats ${b}`;
            }
            if (this.doesItWin(a, b) === 0.5) {
                return `It's a draw`;
            }
            if (this.doesItWin(a, b) === 0) {
                return `${b} beats ${a}`;
            }
        },

        player1Score() {
            return this.doesItWin(this.round1move, this.round1ChallengerMove) +
                this.doesItWin(this.round2move, this.round2ChallengerMove) +
                this.doesItWin(this.round3move, this.round3ChallengerMove)
        },

        challengerScore() {
            return this.doesItWin(this.round1ChallengerMove, this.round1move) +
                this.doesItWin(this.round2ChallengerMove, this.round2move) +
                this.doesItWin(this.round3ChallengerMove, this.round3move)
        },

        gameOverStatus () {
            const p1 = this.player1Score()
            const ch = this.challengerScore()
            if (p1 < ch) return `${this.isPlayer2 ? 'You' : 'Challenger'}'ve taken ${ch} TEZ.`
            if (p1 > ch) return `${this.isPlayer1 ? 'You' : 'Player1'}'ve taken ${p1} TEZ.`
            return `It's a Draw.`
        },

        convertToSeconds(value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
            let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours < 10) {
                hours = '0' + hours;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
        },
    },
};
</script>

<style>
.disabled {
    opacity: 0.4;
}
#battle {
    background: top center url(/background.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
}
#battle .battle-container {
    text-align: center;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 0 30px;
}
#battle .battle-zone {
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #ffffff33;
    background: #ffffff11;
    border-radius: 8px;
}
#battle .battle-logo {
    width: 120px;
    margin: 20px;
}
/* Top left text */
#battle h1 {
    margin: 0;
    padding: 0;
    z-index: 0;
    font-size: 16px;
    line-height: 1em;
}
#battle h2 {
    font-family: 'Turret Road', 'Arial Black', 'Arial', monospace;
    margin: 0;
    z-index: 0;
    font-size: 120px;
    line-height: 0.6em;
}
.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0px;
    margin-bottom: -20px;
}
.toolbar div {
    flex: 1;
}
.toolbar div:first-child {
    text-align: left;
}
.toolbar div:last-child {
    text-align: right;
}
.toolbar span {
    color: #f5831e;
    font-weight: bold;
    cursor: pointer;
}
.toolbar span:hover {
    text-decoration: underline;
}
#timer {
    justify-content: center;
    margin-top: -20px;
}
#timer div {
    width: 180px;
    height: 180px;
    display: flex;
    margin: 0 auto;
    background: #00000088;
    border-radius: 1000em;
    justify-content: center;
    align-items: center;
    border: 8px solid #f5831e;
}
#timer div span label#minutes,
#timer div span label#hours {
    font-family: 'Currier', 'Courier New', Courier, monospace;
    font-size: 54px;
    display: flex;
}

#timer div span label#vs {
    font-family: 'Turret Road', 'Arial Black', 'Arial', monospace;
    font-size: 80px;
    opacity: 1;
}
#player {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: -100px;
    margin-bottom: 40px;
}
#player .avatar {
}
#player .game-intro {
    padding: 0 20px;
}
#player .stats {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 2em;
    width: 50%;
    margin: 0 30px;
}
#player .stats > div:not(:last-child) {
    border-bottom: 1px solid #333;
    margin: -3px 0;
}
#player .stats > div {
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
}
#player .stats > div span:nth-child(1),
#player .stats > div span:nth-child(3) {
    /* display: inline-flex; */
    width: 32%;
}
#player .stats > div span:nth-child(1) {
    text-align: right;
}
#player .stats > div span:nth-child(3) {
    text-align: left;
}
#player .stats > div span:nth-child(2) {
    font-family: 'Turret Road', 'Arial Black', 'Arial', monospace;
}
.moves-container {
    margin-top: 30px;
    padding: 30px 20px;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    background: #111111aa;
    border: 1px solid #ffffff33;
    /* background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0) 70%,
            rgba(255, 255, 255, 0.2) 100%
        ),
        linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0) 70%,
            rgba(255, 255, 255, 0.2) 100%
        ); */
}
.moves-container .column-title span {
    display: block;
    font-size: 21px;
    opacity: 0.8;
}
.moves-container .column-title span + span {
    font-size: 16px;
    opacity: 1;
    font-weight: bold;
}
.moves-container .table-logo {
    height: 40px;
}
.moves-container .arena-title {
    border: 0;
    font-size: 28px;
    font-weight: bold;
    margin: 12px 0 48px;
}
.moves-container table {
    border-collapse: collapse;
    width: 100%;
}
.moves-container table td {
    word-break: keep-all;
    white-space: nowrap;
    text-align: center;
}

.moves-container table tr:not(:first-child) td {
    border-bottom: 1px solid #333;
    border-right: 1px solid #222;
}
.moves-container table tr:last-child td {
    border-bottom: 0;
}
.moves-container table td.winner {
    background: url(/mp4/flying_kick.mp4);
}
.moves-container table tr td:last-child {
    border-right: 0;
}
.moves-container table td.round h4 {
    width: 120px;
    margin: 25px auto;
}
.moves-container table td.round h4 span {
    font-size: 12px;
    display: block;
    text-transform: uppercase;
    opacity: 0.7;
}
.moves-container div.move {
    border-radius: 5px;
    position: relative;
    width: auto;
    display: inline-block;
    margin: 10px 10px;
    text-align: center;
}
/* .moves-container div.move video, */
.moves-container div.move .video {
    display: flex;
    justify-content: center;
    height: 70px;
    width: 130px;
    box-shadow: 0 0 0 1px #f5831eaa, 0 0 5px #f5831e;
    border-radius: 10px;
    background: #000;
}
.moves-container div.move video:hover,
.moves-container div.move .video:hover {
    box-shadow: 0 0 0 2px #f5831e;
}
.moves-container div.move .video img {
    opacity: 0.5;
}
.moves-container .move-display {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
.moves-container .move-display-winner {
}
.moves-container .move-display-winner .move {
    opacity: 0.5;
}
.moves-container .video-winner {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    margin: 10px;
    border-radius: 3px;
    
}
.moves-container div.move h2 {
    font-size: 30px;
}
.moves-container div.move h3 {
    width: 100%;
    position: absolute;
    top: 45px;
    margin-top: -18px;
    font-size: 15px;
    opacity: 0.7;
}
.moves-container div.move h5.large-text {
    font-size: 35px;
    margin: 0;
    font-family: 'Turret Road', 'Arial Black', 'Arial', monospace;
}
.moves-container .action-area {
    margin-top: 30px;
}
.moves-container button {
    margin: 20px 10px;
    font-size: 18px;
    border-width: 0px;
    border-color: #999;
    border-radius: 12px;
    padding: 1em 2em;
    background: #000;
    color: #f5831e;
    border: 2px solid #f5831e;
    letter-spacing: -1px;
    font-family: monospace;
}
.moves-container button:hover {
    box-shadow: 0 0 0 2px #f5831e, 0 0 10px #f5831e;
    border-radius: 10px;
}
.moves-container button:disabled,
.moves-container button:disabled:hover {
    border-color: #666;
    color: #888;
    background: #555;
    box-shadow: none;
}
.moves-container .button-side-by-side {
    width: 40%;
}
video {
    display: block;
    width: 100%;
}
#rules {
    display: flex;
    padding: 20px;
    background: #111111aa;
    margin: 30px 0;
    border-radius: 10px;
    border: 1px solid #ffffff33;
}
#rules > div {
    flex: 1;
    margin: 0 10px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: 20px;
    text-align: left;
}
#rules > div:nth-child(odd) {
    margin-left: 0px;
}

#rules > div:nth-child(even) {
    margin-right: 0px;
}

#rules .title {
    font-size: 28px;
    margin: 20px 0;
}

#rules p {
    color: #fff;
    line-height: 1, 8em;
}

.footer {
    max-width: 600px;
    font-size: 11px;
    margin: 0 auto;
    padding: 0 30px;
}

input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    -moz-appearance: none;
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0 auto;
    font: inherit;
    color: #ffffff55;
    width: 2.15em;
    height: 2.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type='radio']::before {
    content: '';
    width: 1em;
    height: 1em;
    -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type='radio']:checked::before {
    transform: scale(1);
}

input[type='radio']:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
}

input[type='radio']:disabled {
    --form-control-color: var(--form-control-disabled);
    color: var(--form-control-disabled);
    cursor: not-allowed;
}

/* Dry */

.ipfs-image {
    width: 220px;
    margin-left: 0;
    height: 300px;
    z-index: 0;
    background: #080808;
    border: 6px solid #999;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 15px 28px 4px #ffffff33;
    background-position: center top;
}
.ipfs-image .sumo-title {
    font-size: 18px;
    margin-top: 10px;
    display: block;
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -110px;
    text-align: center;
}
</style>
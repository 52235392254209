<template>
    <input type="radio" :name="name" :value="move" @click="$emit('update',name,move)"  />
</template>

<script>

export default {
  name: 'MoveSelect',
  props: ['name','move','update']
}
</script>

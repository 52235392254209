<template>
    <div class="footer">
        Chop Sumo is a series of 3 000 unique NFT sumo characters, created exclusively in 3D. Each SUMO is
        diligently programmed and randomly generated living on Tezos!
    </div>
</template>

<script>
export default {
    name: 'BattleFooter',
};
</script>

import { BeaconWallet } from '@taquito/beacon-wallet';
import { TezosToolkit, MichelCodecPacker } from '@taquito/taquito';
import { Tzip12Module, tzip12 } from '@taquito/tzip12';
import { Tzip16Module, tzip16 } from '@taquito/tzip16';
// import { bytes2Char } from "@taquito/utils";

const Tezos = new TezosToolkit(process.env.VUE_APP_TEZOS_RPC_URL);
Tezos.addExtension(new Tzip12Module());
Tezos.addExtension(new Tzip16Module());
Tezos.setPackerProvider(new MichelCodecPacker());

const wallet = new BeaconWallet({
  name: process.env.VUE_APP_TEZOS_DAPP_NAME,
  preferredNetwork: process.env.VUE_APP_TEZOS_NETWORK,
  colorMode: 'dark',
});

// Setting the wallet as the wallet provider for Taquito.
Tezos.setWalletProvider(wallet);

const network = {
  type: process.env.VUE_APP_TEZOS_NETWORK,
  rpcUrl: process.env.VUE_APP_TEZOS_RPC_URL,
};

const requestPermissions = async () => {
  return wallet.requestPermissions({ network });
};

const getActiveAccount = async () => {
  const activeAccount = await wallet.client.getActiveAccount();

  // no active account, we need permissions first
  if (!activeAccount) {
    await requestPermissions();
    return getActiveAccount();
  }

  return activeAccount;
};

const clearActiveAccount = async () => {
  return wallet.client.clearActiveAccount();
};

const getTokenMetadata = async (address, tokenId) => {
  const contract = await Tezos.contract.at(address, tzip12);
  return contract.tzip12().getTokenMetadata(tokenId);
};

const getContractViews = async (address) => {
  const contract = await Tezos.contract.at(address, tzip16);
  return contract.tzip16().metadataViews();
};

const getContract = async (address) => {
  return Tezos.contract.at(address);
};

const getWalletContract = async (address) => {
  return Tezos.wallet.at(address);
};

const getBatch = () => {
  return Tezos.wallet.batch([]);
};

const getUserNfts = async (address) => {
  return await fetch(
    `${process.env.VUE_APP_TZKT_API_URL}/v1/tokens/balances?account=${address}&token.contract=${process.env.VUE_APP_FA2_CONTRACT}&balance.gt=0&limit=1000`,
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

};

export {
  Tezos,
  wallet,
  getActiveAccount,
  requestPermissions,
  clearActiveAccount,
  getTokenMetadata,
  getContractViews,
  getContract,
  getBatch,
  getWalletContract,
  getUserNfts,
};

<template>
    <div>
        <div id="avatar" v-bind:class="{ active: attention }" ref="avatar">
            <span class="sumo-title">{{ title }}</span>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';

export default {
    props: ['anon', 'title', 'attention'],
    data() {
        return {
            camera: null,
            scene: null,
            renderer: null,
            orbitControls: null,
            clock: null,
            composer: null,
            unrealBloomPass: null,
            model: null,
            mixers: [],
            groundMirror: null,
            avatarAnon: this.anon,
            avatarTitle: this.title,
            avatarAttention: this.attention,
        };
    },
    mounted() {
        this.init();
    },

    methods: {
        // initialization
        init() {
            this.createScene(); // Create a scene
            this.createModels(); // Creating models
            this.createLight(); // Create a light source
            this.createCamera(); // Create camera
            this.createRender(); // Create a render
            this.createControls(); // Create control object
            this.createComposer();
            this.render(); // Rendering
        },
        // Create a scene
        createScene() {
            this.scene = new THREE.Scene();
        },
        createCube() {
            const cubeSize = Math.ceil(Math.random() * 3);
            const cubeGeometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
            const material = new THREE.MeshLambertMaterial({
                color: Math.random() * 0xffffff,
            });
            const cube = new THREE.Mesh(cubeGeometry, material);

            // Set random coordinates for blocks
            cube.position.x = -30 + Math.round(Math.random() * 60);
            cube.position.y = Math.round(Math.random() * 5);
            cube.position.z = -20 + Math.round(Math.random() * 40);
            // Add the generated blocks to the scene
            this.scene.add(cube);
        },
        createSphere() {
            const sphereSize = Math.ceil(Math.random() * 1);
            const sphereGeometry = new THREE.SphereGeometry(sphereSize, 50, 50);
            const material = new THREE.MeshLambertMaterial({
                color: Math.random() * 0xffffff,
            });
            const mesh = new THREE.Mesh(sphereGeometry, material);

            // Set random coordinates
            mesh.position.x = -30 + Math.round(Math.random() * 60);
            mesh.position.y = Math.round(Math.random() * 5);
            mesh.position.z = -20 + Math.round(Math.random() * 40);
            // Add the resulting ball to the scene
            this.scene.add(mesh);
        },
        createGLTF() {
            let me = this;
            const loader = new GLTFLoader();
            loader.load(
                me.anon ? './gltf/anon.glb' : './gltf/sumo_default.glb',
                function (gltf) {
                    // gltf.scene.traverse(function( node ) {
                    //     if ( node.isMesh ) { node.castShadow = true; }
                    // });
                    me.model = gltf;
                    me.model.scene.position.set(0.2, -4, 0);
                    me.model.scene.scale.set(8, 8, 8);
                    me.scene.add(me.model.scene);

                    let geometry;

                    geometry = new THREE.CircleGeometry(400, 640);
                    me.groundMirror = new Reflector(geometry, {
                        clipBias: 0.003,
                        textureWidth: window.innerWidth * window.devicePixelRatio,
                        textureHeight: window.innerHeight * window.devicePixelRatio,
                        color: 0x777777,
                    });
                    me.groundMirror.position.y = -4.5;
                    me.groundMirror.rotateX(-Math.PI / 2);
                    me.scene.add(me.groundMirror);

                    // Animations
                    // const mesh = me.model.scene.children[0];
                    // const mixer = new THREE.AnimationMixer(mesh);
                    // mixer.clipAction(gltf.animations[3]).setDuration(1).play();
                    // me.mixers.push(mixer);

                    // Options
                    // gltf.animations; // Array<THREE.AnimationClip>
                    // gltf.scene; // THREE.Group
                    // gltf.scenes; // Array<THREE.Group>
                    // gltf.cameras; // Array<THREE.Camera>
                    // gltf.asset; // Object
                },
                function (xhr) {
                    console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
                },
                function (error) {
                    console.log('An error happened', error);
                },
            );
        },
        // Creating models
        createModels() {
            // for (let i = 0; i < 10; i++) {
            //   this.createCube()
            //   this.createSphere()
            // }
            this.createGLTF();
        },
        // Create a light source
        createLight() {
            // Ambient light
            const ambientLight = new THREE.AmbientLight(0xffffff, 100); // Create ambient light
            !this.avatarAnon ?? this.scene.add(ambientLight); // Add ambient light to the scene

            const directionLight = new THREE.DirectionalLight(0xffffff);
            directionLight.position.set(550, 100, 550);
            directionLight.intensity = 1.4;
            this.scene.add(directionLight);
        },
        // Create camera
        createCamera() {
            const element = this.$refs.avatar;
            const width = element.clientWidth; // Window width
            const height = element.clientHeight; // Window height
            const k = width / height; // Window aspect ratio
            // PerspectiveCamera( fov, aspect, near, far )
            this.camera = new THREE.PerspectiveCamera(45, k, 0.1, 1000);
            this.camera.position.set(-10, 6, 9); // Set camera position
            this.camera.lookAt(new THREE.Vector3(0, 0, 0)); // Set camera orientation
        },
        // Create a render
        createRender() {
            const element = this.$refs.avatar;
            this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            this.renderer.setSize(element.clientWidth, element.clientHeight); // Set render area size
            this.renderer.shadowMap.enabled = true; // Show shadows
            this.renderer.setClearColor(0x080808, 1); // Set background color
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.gammaOutput = true;
            this.renderer.gammaFactor = 2.1;
            this.renderer.physicallyCorrectLights = true;
            this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
            this.renderer.toneMappingExposure = 0.8;
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            element.appendChild(this.renderer.domElement);
            const environment = new RoomEnvironment();
            const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
            this.scene.environment = pmremGenerator.fromScene(environment).texture;
        },

        createComposer() {
            //Create a RenderPass channel with a scene and camera
            const renderPass = new RenderPass(this.scene, this.camera);

            //Create an UnrealBloomPass Omni channel
            this.unrealBloomPass = new UnrealBloomPass(new THREE.Vector2(256, 256), 1, 1.1, 0.18);
            this.unrealBloomPass.renderToScreen = true;

            this.glithPass = new GlitchPass();

            //Create effect combiner
            this.composer = new EffectComposer(this.renderer);

            //Add the created channel to the effect composer object
            this.composer.addPass(renderPass);
            // this.composer.addPass(this.unrealBloomPass);
            // this.composer.addPass(this.glithPass)
        },

        render() {
            const delta = this.clock.getDelta(); // Gets the time difference since the last call
            this.orbitControls.update(delta); // Camera control update

            this.renderer.render(this.scene, this.camera);

            /********** The effect combiner must be updated after the render is updated, otherwise the channel cannot produce effects************/
            this.composer.render(delta); //Effect combiner update

            // Mixers
            for (let i = 0; i < this.mixers.length; i++) {
                this.mixers[i].update(delta);
            }
            if (this.model !== null) this.model.scene.rotation.y += 0.005;

            requestAnimationFrame(this.render);
        },
        // Create control object
        createControls() {
            this.clock = new THREE.Clock(); // Create the there. Clock object to calculate the time of the last call
            this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
            this.orbitControls.rotateSpeed = 1.0;
            this.orbitControls.zoomSpeed = 5;
            this.orbitControls.panSpeed = 0.8;
            this.orbitControls.noZoom = false;
            this.orbitControls.noPan = false;
            this.orbitControls.staticMoving = true;
            this.orbitControls.dynamicDampingFactor = 0.3;
        },
    },
};
</script>

<style>
#avatar {
    width: 220px;
    margin-left: 0;
    height: 300px;
    z-index: 0;
    background: #080808;
    border: 6px solid #aaa;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 15px 28px 4px #ffffff33;
}
#avatar .sumo-title {
    font-size: 18px;
    margin-top: 10px;
    display: block;
    position: absolute;
    left: 50%;
    width: 100%;
    margin-left: -110px;
    text-align: center;
}
</style>

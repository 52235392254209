<template>
    <div id="rules">
        <div>
            <h4 class="title">How To Play</h4>
            <p>
                Each game of Chop Sumo will put two minted sumos up against each other in a three round match.
                Each battle will be randomly generated moves inspired by Rock Paper Scissors.
                <br />
            <ol>
                <li><a href="#">Get a Sumo into your Tezos wallet.</a></li>
                <li>Connect your wallet and select a Sumo.</li>
                <li>Create a game and pay your 1.5 TEZ.</li>
                <li>Send link to competitor on our <a :href="discordUrl">Discord</a>.</li>
                <li>Wait for challenger to accept and pay his share.</li>
                <li>Wait for the fight to recieve enough confirmations.</li>
                <li>Wallet gets credited outcome of the match.</li>
            </ol>
            </p>
        </div>
        <div>
            <h4 class="title">Rules of the Game</h4>
            <p>

                The entry fee for each player is 1.5 TEZ per game. Winning a round will result in 1 TEZ reward.
                Losing a round will result in 0 TEZ, and drawing a round rewards 0.5 TEZ per player.

                Here are the move rankings:
                <br />
            <ul>
                <li>Headbutt beats Power Punch and Body Slam.</li>
                <li>Body Slam beats Overhead Toss and Flying Punch.</li>
                <li>Flying Kick beats Body Slam and Headbutt.</li>
                <li>Overhead Throw beats Headbutt and Flying Kick.</li>
                <li>Power Punch beats Flying Kick and Overhead Toss.</li>
            </ul>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rules',
    data() {
        return {
            discordUrl: 'https://discord.com/invite/mCxF7PxRh9',
        }
    }
};
</script>

<template>
    <div id="battle">
        <div class="battle-container">
            <div class="battle-zone">
                <div id="header">
                    <div class="toolbar">
                        <div>
                            <h1>
                                <router-link class="back-link" to="/battle">&larr; Back to Battle list</router-link>
                            </h1>
                        </div>
                        <div>
                            <span v-if="wallet.connected === false" @click="connectWallet">Connect Wallet</span>
                            <span plain v-else @click="disconnectWallet">Unsync</span>
                        </div>
                    </div>
                    <router-link to="/">
                        <img src="./../assets/logo.png" class="battle-logo" />
                    </router-link>
                    <h2>BATTLE!</h2>
                </div>
                <div id="timer">
                    <div>
                        <span>
                            <label id="vs">VS</label>
                        </span>
                    </div>
                </div>
                <div id="player">
                    <div class="avatar" :class="{ disabled: !wallet.connected }">
                        <div v-if="wallet.connected && wallet.tokens.length > 0" class="ipfs-image" :style="`background-image: url(${wallet.tokens[0].thumbnailUri})`">
                            <span class="sumo-title">{{ wallet.tokens[0].name }}</span>
                        </div>
                        <ThreeAvatar v-if="!wallet.connected || wallet.tokens.length == 0" title="Load Your Sumo"></ThreeAvatar>
                    </div>
                    <div class="stats">
                        <div class="cta">
                            <el-button plain class="button" v-if="wallet.connected === false"
                                @click="connectWallet">Connect Wallet</el-button>
                            <div class="game-intro">
                                Select your three secret moves below and commit by
                                paying the 1.5 TEZ ante required to start the battle.
                            </div>

                        </div>
                    </div>
                    <div class="avatar" v-bind:class="{ disabled: !this.isGameCreated }">
                        <ThreeAvatar anon="true" title="Find A Challenger"></ThreeAvatar>
                    </div>
                </div>
            </div>

            <!-- Moves Select - Game Init -->
            <div class="moves-container" :class="this.state === 'init' ? '' : 'disabled'">
                <div>
                    <div class="arena-title">Select Your Secret Moves</div>
                    <div v-if="$route.params.hash">Joining Game: {{ $route.params.hash }}</div>
                </div>
                <table>
                    <tr>
                        <td>
                            <img class="table-logo" src="./../assets/logo2.png" />
                        </td>
                        <td>
                            <MoveDisplay name="slam"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="butt"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="punch"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="kick"></MoveDisplay>
                        </td>
                        <td>
                            <MoveDisplay name="throw"></MoveDisplay>
                        </td>
                    </tr>
                    <tr>
                        <td class="round round1">
                            <h4>Round 1</h4>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[0]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[1]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[2]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[3]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round1" :move="movesNames[4]" @update="updateMoves"></MoveSelect>
                        </td>
                    </tr>
                    <tr>
                        <td class="round round2">
                            <h4>Round 2</h4>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[0]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[1]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[2]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[3]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round2" :move="movesNames[4]" @update="updateMoves"></MoveSelect>
                        </td>
                    </tr>
                    <tr>
                        <td class="round round3">
                            <h4>Round 3</h4>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[0]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[1]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[2]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[3]" @update="updateMoves"></MoveSelect>
                        </td>
                        <td>
                            <MoveSelect name="round3" :move="movesNames[4]" @update="updateMoves"></MoveSelect>
                        </td>
                    </tr>
                </table>
                <div class="action-area">
                    <div
                        v-if="round1move === 'unknown' || round2move === 'unknown' || round3move === 'unknown'">
                        Select your 3 moves from above</div>
                    <div
                        v-if="round1move !== 'unknown' && round2move !== 'unknown' && round3move !== 'unknown'">
                        Nice! Now start your game</div>
                    <el-button plain class="button" v-if="wallet.connected === false"
                        @click="connectWallet">Connect Wallet to Play</el-button>
                    <el-button plain class="button" v-if="wallet.connected" @click="createGame"
                        :disabled="round1move === 'unknown' || round2move === 'unknown' || round3move === 'unknown'">
                        <span v-if="!isWaitingToCreateGame && !isGameCreated">Create A Game - 1.5 TEZ</span>
                        <span v-if="isWaitingToCreateGame">Please Wait...</span>
                        <span v-if="isGameCreated">Game Created</span>
                    </el-button>
                </div>
            </div>

            <!-- Rules -->
            <game-rules />
            <battle-footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { buf2hex } from '@taquito/utils';
// import VueRouter from "vue-router";
import ThreeAvatar from '../components/ThreeAvatar';
import MoveSelect from '../components/MoveSelect';
import MoveDisplay from '../components/MoveDisplay';
import GameRules from '../components/Rules';
import BattleFooter from '../components/BattleFooter';

const FEE = 1500000

// const DRAW = '05'
// const WIN = '06'
// const LOSE = '07'

export default {
    name: 'battle',
    components: {
        ThreeAvatar,
        MoveSelect,
        MoveDisplay,
        GameRules,
        BattleFooter
    },
    data() {
        return {
            state: 'init', // init, created, over
            isActive: true,
            movesNames: ['slam', 'butt', 'punch', 'kick', 'throw'],
            discordUrl: 'https://discord.com/invite/mCxF7PxRh9',
            isGameCreated: false,
            isWaitingToCreateGame: false,
            round1move: 'unknown',
            round2move: 'unknown',
            round3move: 'unknown',
            round1ChallengerMove: 'slam',
            round2ChallengerMove: 'punch',
            round3ChallengerMove: 'throw',

            canCancelGame: false,

            canReveal: false,
            playerHasJoined: false,

            cancelTimeout: 10, // (10 seconds)
            isWaitingForPlayerJoin: false,
            isWaitingToRevealMoves: false,

            isGameOver: false,
            commitment: '',
        };
    },
    computed: {
        ...mapState(['wallet']),
        gameState() {
            const { round1move, round2move, round3move } = this
            return [round1move, round2move, round3move]
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions(['connectWallet', 'disconnectWallet', 'changeWallet', 'queryForNfts']),

        init() {
            try {
                this.queryForNfts();
            } catch (e) {
                console.log(e);
            }
            setInterval(() => {
                if (this.isGameCreated && this.cancelTimeout > 0) this.cancelTimeout--;
                if (this.cancelTimeout === 0 && !this.canCancelGame) this.canCancelGame = true;
            }, 1000);
        },
        newGame() {
            this.state = 'init';
            this.isGameCreated = false;
            this.isGameOver = false;
            this.playerHasJoined = false;
            this.canReveal = false;
            this.round1move = 'unknown';
            this.round2move = 'unknown';
            this.round3move = 'unknown';
            this.cancelTimeout = 10

        },
        randomHex () {
          const secret = new Uint8Array(16)
          self.crypto.getRandomValues(secret)
          return buf2hex(secret)
        },
        async createGame() {
            try {
                if (this.isWaitingToCreateGame) return

                this.isWaitingToCreateGame = true;

                const id = this.wallet.tokens[0]?.tokenId

                if (!id) throw new Error("Chop Sumo token not found");

                const params = { game: this.gameState, secret: this.randomHex() }

                localStorage.setItem(`${id}`, JSON.stringify({ ...params, pkh: this.wallet.pkh }))

                const result = await this.$store.dispatch('createGame', { ...params, id, fee: FEE })

                if (!result) return // game creation aborted
 
                console.log(JSON.stringify(result))
 
                const { opHash } = result

                const gameHash = await this.waitForGameHash(opHash)

                this.$router.push(`/battle/${gameHash}`)
            } catch (e) {
                this.$toast.error(e.message)
            } finally {
                this.isWaitingToCreateGame = false;
            }
        },
        async waitForGameHash (hash) {
          let gameHash
          while (!gameHash) {
            gameHash = await this.$store.dispatch('getGameHash', hash)
            if (!gameHash) {
                console.log('waiting')
                setTimeout(() => this.waitForGameHash(hash), 5000)
            }
          }
          return gameHash
        },
        updateMoves(round, move) {
            console.log('updates', round, move);
            if (round === 'round1') {
                this.round1move = move;
            }
            if (round === 'round2') {
                this.round2move = move;
            }
            if (round === 'round3') {
                this.round3move = move;
            }
        },

        cancelGame() {
            console.log('canceling game');
        },

        letPlayerJoin() {
            this.isWaitingForPlayerJoin = true;
            setTimeout(() => {
                this.isWaitingForPlayerJoin = false;
                this.playerHasJoined = true;
                this.canReveal = true;
                this.canCancelGame = false;
            }, 3000);
        },

        revealGame() {
            this.isWaitingToRevealMoves = true;
            setTimeout(() => {
                this.isWaitingToRevealMoves = false;
                this.isGameOver = true;
                this.state = 'gameover';
            }, 3000);
        },

        doesItWin(a, b) {
            // butt beats punch and slam.
            // slam beats trow and punch.
            // kick beats slam and butt.
            // throw beats butt and kick.
            // punch beats kick and throw.
            if (a === b) return 0.5; // Draw

            // Wins for sure
            if (a === 'butt' && ['punch', 'slam'].includes(b)) return 1;
            if (a === 'slam' && ['throw', 'punch'].includes(b)) return 1;
            if (a === 'kick' && ['slam', 'butt'].includes(b)) return 1;
            if (a === 'throw' && ['butt', 'kick'].includes(b)) return 1;
            if (a === 'punch' && ['kick', 'throw'].includes(b)) return 1;

            // Loose scenarios
            if (b === 'butt' && ['punch', 'slam'].includes(a)) return 0;
            if (b === 'slam' && ['throw', 'punch'].includes(a)) return 0;
            if (b === 'kick' && ['slam', 'butt'].includes(a)) return 0;
            if (b === 'throw' && ['butt', 'kick'].includes(a)) return 0;
            if (b === 'punch' && ['kick', 'throw'].includes(a)) return 0;

            return 0.5; // Draw (Impossible?)
        },

        doesItWinCopy(a, b) {
            if (this.doesItWin(a, b) === 1) {
                return `${a} beats ${b}`;
            }
            if (this.doesItWin(a, b) === 0.5) {
                return `It's a draw`;
            }
            if (this.doesItWin(a, b) === 0) {
                return `${b} beats ${a}`;
            }
        },

        yourScore() {
            return this.doesItWin(this.round1move, this.round1ChallengerMove) +
                this.doesItWin(this.round2move, this.round2ChallengerMove) +
                this.doesItWin(this.round3move, this.round3ChallengerMove)
        },
        challengerScore() {
            return this.doesItWin(this.round1ChallengerMove, this.round1move) +
                this.doesItWin(this.round2ChallengerMove, this.round2move) +
                this.doesItWin(this.round3ChallengerMove, this.round3move)
        },


        convertToSeconds(value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
            let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours < 10) {
                hours = '0' + hours;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
        },
    },
};
</script>


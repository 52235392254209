<template>
  <div id="home">
    <el-container id="countdown">
      <div v-if="numMinted < maxMint" style="width: 100%">
        <span>Public minting is open!</span>
      </div>
      <div v-else style="width: 100%">
        <span>
          <span>WE ARE SOLD OUT! THANK YOU!</span>
          <a href="https://objkt.com/collection/chopsumo">View collection on Objkt.com<i
              class="far fa-external-link"></i></a>
        </span>
      </div>
    </el-container>

    <el-container id="app">
      <el-header style="height: auto;">
        <img src="./../assets/logo.png" class="logo" />
        <div class="links">
          <a :href="discordUrl"><i class="fab fa-discord"></i></a>
          <a href="https://twitter.com/chopsumoz"><i class="fab fa-twitter"></i></a>
          <el-button plain v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet">Sync
          </el-button>
          <el-button plain v-else @click="disconnectWallet">Unsync</el-button>
          <router-link class="filled" to="/battle">Battle!</router-link>
          <span v-if="wallet.connected" style="margin-left: 15px;">{{ `${wallet.pkh.substr(0,
              6)}...${wallet.pkh.substr(-6)}`
          }}</span>
        </div>
      </el-header>
      <el-main>

        <div style="min-height: 625px;">
          <el-row class="hero">
            <el-col :xs="24" :md="12">
              <h1>Unrivaled Sumo art on the Tezos blockchain</h1>
            </el-col>
          </el-row>
          <el-row class="hero">
            <el-col :xs="24" :md="9">
              <p>Chop Sumo is a series of 3 000 unique NFT sumo characters, created exclusively in 3D. Each SUMO is
                diligently programmed and randomly generated. They all live on the Tezos blockchain. With billions of
                possible variations and a handful of super rare SUMOS, your next three moves are crucial: 1) choose your
                corner 2) gather your strength and 3) slice 'em up!</p>
              <div class="mint" v-if="isMinting && numMinted < maxMint" v-loading="mintOpPending"
                element-loading-background="rgba(0, 0, 0, 0.6)" style="padding: 12px;">
                <el-select v-model="mintNum" placeholder="Select # to mint">
                  <el-option v-for="item in mintOpts" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-button type="warning" @click="mint">Mint</el-button>

                <el-progress :show-text="false" :stroke-width="22" :percentage="parseInt((numMinted / maxMint) * 100)"
                  style="margin-top: 15px; margin-bottom: 8px"></el-progress>
                {{ numMinted }} / {{ maxMint }} minted

              </div>
              <div class="mint" v-else-if="isMinting">
                <H2>SOLD OUT</H2>
                <el-progress :show-text="false" :stroke-width="22" :percentage="100"
                  style="margin-top: 15px; margin-bottom: 8px"></el-progress>
                <p style="margin-top: 0; margin-bottom: 8px;">{{ numMinted }} / {{ maxMint }} minted</p>
                <a href="https://objkt.com/collection/chopsumo">View collection on Objkt.com<i
                    class="far fa-external-link"></i></a>
              </div>
              <div class="mint" v-else>
                <el-button type="warning" disabled>Minting soon!</el-button>
              </div>
            </el-col>
          </el-row>
        </div>

        <el-row style="margin-top: 25px">
          <el-col :xs="24" :md="12" style="text-align: center;">
            <img src="./../assets/sumos1.png" />
          </el-col>
          <el-col :xs="24" :md="12" style="margin-top: 15px;">
            <h1>Prepare to fight!</h1>
            <p>Each Sumo is a power player in its own right. Built for performance, crafted with immaculate precision
              and ready to wrestle on the Tezo’s blockchain. Like any other master, prepare to see your Sumo’s true
              greatness once it reveals itself in battle. Each sliced up Sumo will be coming in hot and heavy, so be
              prepared to stand your ground.</p>
            <p>Everyone needs a Sumo on their side. Unfortunately these gentlemen of strength come in limited numbers.
              Chop Sumos are split into two divisions… an army of incredible artistic muscle and blockchain power, and a
              few super rare Sumos for those buyers with lighting fast reflexes. Hakkiyoi! Come on… let the grappling
              begin!</p>
          </el-col>
        </el-row>

        <div class="team">
          <el-row style="margin-top: 150px">
            <el-col :span="24" style="text-align: center;">
              <img src="./../assets/logo2.png" style="width: 50px;" />
              <h1 style="margin-top: 32px;">The Team</h1>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-around" style="margin-top: 25px" class="hidden-sm-and-down">
            <el-col :span="6">
              <el-avatar :size="64" :src="avatar1" style="float: left; margin-right: 15px;"></el-avatar>
              <h2 style="margin-top: 0;">Tim Hart<a href="https://twitter.com/timcybernetix"
                  style="color: #fff; margin-left: 15px;"><i class="fab fa-twitter"></i></a></h2>
              <span style="color: #F5831E; font-size: 14px">Artist</span>
              <p>Tim Hart has been working in the motion graphics/ 3D industry for the last 5 years, with brands such as
                Adidas, Johnny Walker and Discovery under his belt. His love for crypto, proceduralism, randomisation
                and the Far East gave birth to the idea to combine all those things, in Chop Sumo! <a
                  href="https://www.instagram.com/cybernetix_3d/">Check out more of his work</a></p>
            </el-col>
            <el-col :span="6">
              <el-avatar :size="64" :src="avatar2" style="float: left; margin-right: 15px;"></el-avatar>
              <h2 style="margin-top: 0;">Josh Dechant<a href="https://twitter.com/mycodecrafting"
                  style="color: #fff; margin-left: 15px;"><i class="fab fa-twitter"></i></a></h2>
              <span style="color: #F5831E; font-size: 14px">Lead Dev</span>
              <p>Josh is a software engineer with 20+ years of experience and is the founder of Crunchy, partner at
                Chain of Insight, and Senior Engineer for Interpop. Josh authored the TZIP-021 rich token metadata
                standards for Tezos. You can often find him in the various Tezos related Discord severs and Telegram
                channels.</p>
            </el-col>
            <el-col :span="6">
              <el-avatar :size="64" :src="avatar3" style="float: left; margin-right: 15px;"></el-avatar>
              <h2 style="margin-top: 0;">Frank Kirk<a href="https://twitter.com/one_bald_dude"
                  style="color: #fff; margin-left: 15px;"><i class="fab fa-twitter"></i></a></h2>
              <span style="color: #F5831E; font-size: 14px">Product Designer</span>
              <p>Frank is a Tezos ICO participant, baker and current product designer for Crunchy Network. Frank has 5+
                years of experience leading product and design for military and commercial projects. If you have seen
                him on Twitter, you know that he is an opinionated baker and very passionate about Tezos and NFTs.</p>
            </el-col>
          </el-row>
          <el-row style="margin-top: 25px; padding: 20px;" class="hidden-md-and-up">
            <el-col :span="24">
              <el-avatar :size="64" :src="avatar1" style="float: left; margin-right: 15px;"></el-avatar>
              <h2 style="margin-top: 0;">Tim Hart<a href="https://twitter.com/timcybernetix"
                  style="color: #fff; margin-left: 15px;"><i class="fab fa-twitter"></i></a></h2>
              <span style="color: #F5831E; font-size: 14px">Artist</span>
              <p>Tim Hart has been working in the motion graphics/ 3D industry for the last 5 years, with brands such as
                Adidas, Johnny Walker and Discovery under his belt. His love for crypto, proceduralism, randomisation
                and the Far East gave birth to the idea to combine all those things, in Chop Sumo! <a
                  href="https://www.instagram.com/cybernetix_3d/">Check out more of his work</a></p>
            </el-col>
            <el-col :span="24" style="margin-top: 25px;">
              <el-avatar :size="64" :src="avatar2" style="float: left; margin-right: 15px;"></el-avatar>
              <h2 style="margin-top: 0;">Josh Dechant<a href="https://twitter.com/mycodecrafting"
                  style="color: #fff; margin-left: 15px;"><i class="fab fa-twitter"></i></a></h2>
              <span style="color: #F5831E; font-size: 14px">Lead Dev</span>
              <p>Josh is a software engineer with 20+ years of experience and is the founder of Crunchy, partner at
                Chain of Insight, and Senior Engineer for Interpop. Josh authored the TZIP-021 rich token metadata
                standards for Tezos. You can often find him in the various Tezos related Discord severs and Telegram
                channels.</p>
            </el-col>
            <el-col :span="24" style="margin-top: 25px;">
              <el-avatar :size="64" :src="avatar3" style="float: left; margin-right: 15px;"></el-avatar>
              <h2 style="margin-top: 0;">Frank Kirk<a href="https://twitter.com/one_bald_dude"
                  style="color: #fff; margin-left: 15px;"><i class="fab fa-twitter"></i></a></h2>
              <span style="color: #F5831E; font-size: 14px">Product Designer</span>
              <p>Frank is a Tezos ICO participant, baker and current product designer for Crunchy Network. Frank has 5+
                years of experience leading product and design for military and commercial projects. If you have seen
                him on Twitter, you know that he is an opinionated baker and very passionate about Tezos and NFTs.</p>
            </el-col>
          </el-row>
        </div>

        <div class="faq">
          <el-row :gutter="30" type="flex" align="bottom">
            <el-col :xs="24" :md="16">
              <h1 style="margin: 0;">Tell me more about this!</h1>
            </el-col>
            <el-col :xs="24" :md="8" v-if="isMinting && numMinted < maxMint">
              <el-select v-model="mintNum" placeholder="Select # to mint">
                <el-option v-for="item in mintOpts" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-button type="warning" @click="mint" style="padding: 12px 50px;">Mint</el-button>
            </el-col>
            <el-col :xs="24" :md="8" v-else-if="!isMinting">
              <el-button type="warning" disabled>Minting soon!</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 50px;">
            <el-col :xs="24" :md="12">
              <h2>Chop Sumos are generative art. What’s that?</h2>
              <p>This is when the artist intentionally includes randomness as part of the creative process. It creates a
                sort of lottery or sale, where neither the collector nor the artist knows the exact end result. Make the
                match even more exciting! With NFTs, most artists will ensure each minted piece is totally unique.</p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>How will you ensure fair distribution?</h2>
              <p>The contract includes a provenance hash. This is a SHA-256 hash of an IPFS directory that refers to a
                fingerprint of the tokens and the art. It means anyone can verify that we didn’t allocate Sumos after
                minting them. Also, when the sale starts, the harbinger price oracle (https://harbinger.live/) is used
                to randomize the starting id of the sale.</p>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 50px;">
            <el-col :xs="24" :md="12">
              <h2>What is the mint price?</h2>
              <p>Mint price is set at <strong>17.5 XTZ</strong></p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>How many can I mint?</h2>
              <p>Whitelisted wallets will be allowed to mint up to <strong>5 SUMOs</strong> before the public sale. All
                wallets are capped at minting a total of <strong>10 SUMOs</strong>.</p>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 50px;">
            <el-col :xs="24" :md="12">
              <h2>How do I get on the whitelist?</h2>
              <p>Please <a :href="discordUrl">join our Discord</a> to find details on how to enter and participate in
                the whitelisting process. Whitelist process closes on November 1, 2021 at 12:00 UTC</p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>When can I mint?</h2>
              <p>Whitelist minting opens November 3, 2021 at 14:00 UTC and runs for 24 hours. Public minting will
                commence shortly after.</p>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 50px;">
            <el-col :xs="24" :md="12">
              <h2>I minted my Chop Sumo. Now what?</h2>
              <p>Once you have minted your Sumo, the tokens will be visible in your wallet as well as your objkt.com
                collection page. At first you will only see a placeholder and all the unique Sumo’s traits will be
                hidden. Once the sale ends, the actual Sumo with all its traits and unique markers will be revealed to
                you. This should approximately 24 hours after the sale.</p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>Where can a trade my Sumo?</h2>
              <p>The SUMO FA2 tokens have their own collection on objkt.com and can be traded there.</p>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 50px;">
            <el-col :xs="24" :md="12">
              <h2>Any future plans?</h2>
              <p>We will launch incentives to help our community grow. After the sale, SUMO holders will have perks to
                the project’s future generations. There are however no future gamification incentives planned. This
                project is made purely for the art, because that’s what we’re about.</p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>Provenance &amp; Contracts</h2>
              <p>Crowdsale Contract:<br /><a
                  :href="`https://better-call.dev/mainnet/${crowdSaleContract}/operations`">{{ crowdSaleContract }} <i
                    class="far fa-external-link" style="margin-left: 8px"></i></a></p>
              <p>SUMO NFT Contract:<br /><a :href="`https://better-call.dev/mainnet/${fa2Contract}/operations`">{{
                  fa2Contract
              }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
              <p>Provenance Hash:<br /><strong>{{ provenanceHash }}</strong></p>
            </el-col>
          </el-row>
        </div>

      </el-main>
    </el-container>
    <el-container id="footer">
      <el-footer style="height: 556px;">
        <div class="discord-link">
          <i class="fab fa-discord"></i>
          <h1><a :href="discordUrl">Join our Discord to get more information</a></h1>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from './../utils/tezos';
import tzkt from './../utils/tzkt';

import '../styles.scss'
import '../moves-container.scss'
import '../battle-styles.scss'
import '../rules.scss'

const avatar1 = require('./../assets/sumo-avatar-1.png');
const avatar2 = require('./../assets/sumo-avatar-2.png');
const avatar3 = require('./../assets/sumo-avatar-3.png');

export default {
  name: 'app',
  components: {
    // ThreeTwo
  },
  data() {
    return {
      avatar1,
      avatar2,
      avatar3,
      discordUrl: "https://discord.com/invite/mCxF7PxRh9",
      crowdSaleContract: process.env.VUE_APP_CROWDSALE_CONTRACT,
      fa2Contract: process.env.VUE_APP_FA2_CONTRACT,
      provenanceHash: process.env.VUE_APP_PROVENANCE_HASH,
      saleStartTime: new Date('2021-11-04T15:00:00Z').getTime(),
      numMinted: 3000,
      maxMint: 3000,
      mintOpPending: false,
      mintNum: 10,
      mintOpts: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
        {
          value: '6',
          label: '6'
        },
        {
          value: '7',
          label: '7'
        },
        {
          value: '8',
          label: '8'
        },
        {
          value: '9',
          label: '9'
        },
        {
          value: '10',
          label: '10'
        }
      ]
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    isMinting: function () {
      return (new Date() >= new Date('2021-11-04T15:00:00Z'));
    }
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet',
    ]),

    async updateMinted() {
      const storage = await tzkt.getContractStorage(this.crowdSaleContract);
      this.numMinted = parseInt(storage.data.sale.soldAmount);
      if (this.numMinted < 3000) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint() {
      const amount = parseInt(this.mintNum * 17500000);
      if (amount < 1) {
        return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(this.mintNum)
          .send({
            amount: amount,
            mutez: true,
            fee: 55000,
            gasLimit: 250000,
            storageLimit: 3000
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  }
}
</script>

<style scoped>
#home {
  background: #080808 no-repeat center 115px;
  background-image: url("./../assets/sumo-header-bg1.jpg");
}
</style>
<template>
  <div>
    <!-- <div id="router-nav">
      <router-link to="/">Go Home</router-link> - 
      <router-link to="/battle">Go to Battle</router-link>
    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>

// import '../styles.scss'
// import './moves-container.scss'
// import './battle-styles.scss'
// import './rules.scss'
export default {
    created () {
        this.$store.dispatch('init')
    }
}

</script>

<style scss>
html,
body {
  margin: 0;
  height: 100%;
  background: #080808 no-repeat center 115px;
}

a {
  color: #F5831E;
}

body {
  font-family: "DM Sans", Arial, sans-serif;
  color: #fff;
}

#router-nav {
  position: fixed;
  bottom: 0;
}

#app,
.discord-link {
  max-width: 1280px;
  margin: 0 auto;
}

#app .el-button.is-plain {
  background: transparent;
  border: 1px solid #fff;
  border-color: #fff;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  padding: 10px 54px;
  font-size: 16px;
  border-radius: 5px;
}

#app .el-button.is-plain:hover {
  border-color: #f5831e;
  color: #f5831e;
}

#app .el-select {
  width: 80px;
  margin-right: 15px;
}

#app .el-input__inner {
  background: #000;
  color: #fff;
}

#app .el-progress-bar__outer {
  background: #444;
}

.el-loading-mask {
  border-radius: 12px;
}

.discord-link {
  max-width: 976px;
  margin-top: 75px;
}

.discord-link i {
  font-size: 96px;
}

.discord-link h1 {
  margin-top: 32px;
}

.discord-link h1 a,
.discord-link h1 a:visited {
  text-decoration: none;
  color: #fff;
}

.discord-link h1 a:hover {
  text-decoration: underline;
}

#footer {
  background: #f5831e url("assets/footer-top.png") top left repeat-x;
  height: 556px;
}

#footer .el-footer {
  padding-top: 120px;
  background: transparent url("assets/footer-sumo.png") bottom center no-repeat;
  height: 100%;
}

#countdown {
  background: #f5831e;
  color: #fff;
  padding: 12px;
  font-size: 18px;
  text-align: center;
}

#countdown span {
  display: block;
  width: 100%;
}

#countdown span span {
  font-family: "Turret Road", cursive;
  font-size: 32px;
  margin: 18px 0 12px 0;
}

#countdown a {
  color: #fff;
  text-decoration: none;
}

#countdown a:hover {
  text-decoration: underline;
}

#countdown a i {
  margin-left: 12px;
}

.el-header {
  position: relative;
}

.logo {
  width: 125px;
  margin-top: 25px;
}

.el-header .links {
  position: absolute;
  top: 15px;
  right: 20px;
}

.el-header .links {
  display: flex;
  align-items: center;

}

.el-header .links button,
.el-header .links span,
.el-header .links a,
.el-header .links a:visited {
  text-align: center;
  /* display: block; */
  margin-top: 6px;
  font-size: 18px;
  color: #fff;
  margin-right: 24px;
  text-decoration: none;
  line-height: 32px;
}

.el-header .links button span {
  margin: 0;
}

.el-header .links a:hover {
  color: #f5831e;
}

.el-header .links a.filled {
  background: #f5831e;
  color: #fff;
  padding: 10px 54px;
  border-radius: 5px;
  border: 1px solid #833d00;
}

.el-header .links a.filled:hover {
  background: transparent;
}

h1 {
  font-family: "Turret Road", cursive;
  font-size: 76px;
  margin-bottom: 32px;
  line-height: 66px;
}


p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #838383;
  margin-bottom: 24px;
}

p a {
  color: #fff;
}

.faq {
  margin: 150px 200px;
  margin-bottom: 0;
}

.faq h2 {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 40px;
}

.faq p {
  font-size: 14px;
}

.faq strong {
  color: #ddd;
}

.mint {
  text-align: center;
}

.mint .el-button,
.faq .el-button {
  background-color: #f5831e;
  border-color: #f5831e;
  color: #fff;
  font-weight: 500;
  padding: 12px 72px;
  font-size: 16px;
  border-radius: 5px;
}

.mint a {
  color: #fff;
  font-size: 14px;
}

.mint a i {
  margin-left: 8px;
}

.team {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(8, 8, 8, 1) 60%);
  border-radius: 10px;
}

.team h2 {
  font-size: 18px;
}

.el-avatar {
  background: transparent;
}


.attention {
  animation: myAnim 2s ease 0s infinite normal forwards;
}


@keyframes myAnim {

  0%,
  50%,
  100% {
    border-color: #f5831e;
  }

  25%,
  75% {
    border-color: #ecb27f;
  }
}


@media only screen and (max-width: 991px) {
  .hero p {
    background: rgba(0, 0, 0, 0.75);
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(8, 8, 8, 0.9) 60%);
    padding: 10px;
    border-radius: 10px;
  }

  .mint {
    text-align: left;
  }

  .faq {
    margin: 150px 0px;
  }
}
</style>

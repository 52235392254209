<template>
    <div id="battle">
        <div class="battle-container">
            <div class="battle-zone">
                <div id="header">
                    <div class="toolbar">
                        <div>
                            <h1>Challenge another NFT on the Tezos blockchain!</h1>
                        </div>
                        <div>
                            <span v-if="wallet.connected === false" @click="connectWallet">Connect Wallet</span>
                            <span plain v-else @click="disconnectWallet">Unsync</span>
                        </div>
                    </div>
                    <router-link to="/">
                        <img src="./../assets/logo.png" class="battle-logo" />
                    </router-link>
                    <h2>BATTLE!</h2>
                    <br />
                </div>
            </div>

            <router-link class="big-ass-button" to="/battle/create">
                <div class="title">
                    START A FIGHT!
                </div>
                <p>Battle ante starting from 1.5 TEZ!</p>
            </router-link>

            <div id="moves-container" class="moves-container">
                <div>
                    <div class="arena-title">CHOP SUMO BATTLE LOG</div>
                    <div class="arena-instruction">Click on any of the battles below to join or see results.</div>

                </div>
                <div class="battle-list">
                    <div class="row heading">
                        <div></div>
                        <div>Sumo No.</div>
                        <div>Ante</div>
                        <div>Status</div>
                        <div>Time</div>
                        <div>Protagonist</div>
                        <div>Hash</div>
                    </div>
                    <div v-for="item in this.items" class="row" :class="item.class" :key="item.id">
                        <router-link class="link" :to="{ path: '/battle/' + item.hash }">
                            <img src="../assets/logo2.png" /></router-link>
                        <router-link class="link" :to="{ path: '/battle/' + item.hash }">#{{ item.tokenId }}
                        </router-link>
                        <router-link class="link item-ante" :to="{ path: '/battle/' + item.hash }">{{ item.ante }} &#42793;</router-link>
                        <router-link class="link" :to="{ path: '/battle/' + item.hash }">{{ item.status }}</router-link>
                        <router-link class="link" :to="{ path: '/battle/' + item.hash }"><time-ago :datetime="item.timestamp" long /></router-link>
                        <router-link class="link" :to="{ path: '/battle/' + item.hash }">{{ item.wallet }}</router-link>
                        <router-link class="link" :to="{ path: '/battle/' + item.hash }">{{ item.hash.substring(0, 19) }}</router-link>
                    </div>
                </div>
            </div>

            <Rules />

            <BattleFooter />

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Rules from './../components/Rules';
import BattleFooter from './../components/BattleFooter';
import { TimeAgo } from 'vue2-timeago'
export default {
    name: 'battle',
    components: {
        Rules,
        BattleFooter,
        TimeAgo
    },
    data() {
        return {
            // discordUrl: 'https://discord.com/invite/mCxF7PxRh9',
            timer: null,
            items: []
        };
    },
    computed: {
        ...mapState(['wallet']),
    },
    created() {
        // this.init();
        this.poll()
    },
    beforeDestroy () {
        console.log('battles before destroy')
        clearTimeout(this.timer)
    },
    methods: {
        ...mapActions(['checkWalletConnected', 'connectWallet', 'disconnectWallet', 'changeWallet', 'queryForGames']),

        async poll() {
            const data = await this.queryForGames()
            this.$set(this, 'items', data.map(({ hash, value, key }) => ({
                hash: `${hash}-${key}`,
                wallet: value.player1.address,
                tokenId: value.player1.token_id,
                ante: 1.5,
                status: this.getStatus(value),
                'class': this.getStatus(value).toLowerCase(),
                timestamp: new Date(value.joined || value.created)
            })))
            // console.log(data)
            this.timer = setTimeout(this.poll, 15000)
        },
        getStatus ({ active, joined }) {
            return active ? ( joined ? '' : 'Waiting') : ( joined ? 'Ended' : 'Cancelled')
        }
    },
};
</script>
